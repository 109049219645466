@import url("https://use.typekit.net/xrz7oux.css");

.App {
    text-align: center; // font-family: "pf-marlet-display", sans-serif;
}

.logoTypography {
    width: 450px;
    max-width: 50vw;
}

.logoIcon {
    width: 200px;
    margin: 0 auto;
    position: relative;
    margin-top: 20vh;
    background: #f8ede9;
    padding: 2rem;
    border-radius: 100%;
    margin-bottom: 1.5rem;
}

.logoHorseshoe {
    width: 200px;
    animation: horseshoeAnim 15s infinite alternate;
    fill: #127476;
}

.logoPlumbob {
    position: absolute;
    top: calc(1.5rem + 4.5%);
    left: 50%;
    transform: translateX(calc(-50%));
    max-width: 7%;
    animation: horseshoeAnim 15s infinite alternate-reverse;
    fill: #af6d5b;
}

p {
    margin: 0;
}

a {
    color: #af6d5b;
    font-weight: bold;
}

@keyframes horseshoeAnim {
    0% {
        fill: #127476;
    }

    20% {
        fill: #d09b6c;
    }

    40% {
        fill: #d2a690;
    }

    60% {
        fill: #af6d5b;
    }

    80% {
        fill: #669583;
    }

    100% {
        fill: #d09b6c;
    }
}
